// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

.site-header {
    border-top: none;
}

.gallery {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
	li {
		padding: 0;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		flex: 1 0 300px;
		margin: 10px;
		//margin-bottom: 30px;
		box-sizing: border-box;
		max-width: 450px;
		//text-align: center;

		&:before {
			content: '';
			padding-top: 100%;
			display: block;
			float: left;
		}

		&.spacer {
			height: 0;
			margin-top: 0;
			margin-bottom: 0;
			&:before {
				padding: 0;
			}
		}

		a {
			display: block;
			width: 100%;
			height: 100%;
			transition: background .2s ease;

			&:hover {
				background: rgba(255,255,255,.3);
			}
		}
	}
}